<template>
  <div class="new-user-page admin-page">
    <div class="container">
      <AdminHeader />
      <div class="heading">
        <v-breadcrumbs
          class="breadcrumbs"
          divider="<v-icon>mdi-chevron-right</v-icon>"
          :items="[
            {
              text: 'Benutzer',
              to: '/admin/users',
            },
            {
              text: 'Erstellen',
              to: $route.path,
            },
          ]"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :to="item.to == $route.path ? '' : item.to"
              exact
              exact-active-class="active"
              active-class=""
              :class="item.to == $route.path ? 'active' : ''"
            >
              <h2 class="breadcrumbItem" v-if="item.to == $route.path">
                {{ item.text }}
              </h2>
              <p class="breadcrumbItem" v-else>{{ item.text }}</p>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </div>

      <v-form class="form" ref="userForm" v-model="valid">
        <v-text-field
          class="email input"
          outlined
          label="E-Mail"
          placeholder="max.mustermann"
          suffix="@franz…asium.eu"
          v-model="userForm.email"
          :rules="[rules.required]"
          tabindex="1"
        ></v-text-field>

        <v-text-field
          class="name input"
          outlined
          label="Name"
          placeholder="Max Mustermann"
          v-model="userForm.name"
          :rules="[rules.required]"
          tabindex="2"
        ></v-text-field>

        <h3 class="subheading">Berechtigungen</h3>

        <v-switch
          class="admin input"
          label="Administrator"
          v-model="userForm.admin"
          inset
          tabindex="3"
          @change="changeAdmin"
        ></v-switch>

        <v-select
          class="canDebit input"
          :items="campaignItems"
          v-model="userForm.canDebit"
          label="Abbuchung bei"
          multiple
          outlined
          tabindex="4"
          :disabled="userForm.admin"
          :hint="
            userForm.admin
              ? 'Administratoren können bei allen Kampagnen Abbuchungen vornehmen'
              : null
          "
          :persistent-hint="userForm.admin"
        ></v-select>

        <v-btn
          color="primary"
          elevation="0"
          class="submitBtn"
          tabindex="5"
          :loading="loading"
          @click="addUser()"
          >Benutzer hinzufügen</v-btn
        >
      </v-form>
    </div>

    <v-dialog v-model="error.active" elevation="10" persistent max-width="500">
      <v-card>
        <template v-if="error.code === 'registerError'">
          <v-card-title style="word-break: break-word;">
            Fehler beim Erstellen des Benutzers
          </v-card-title>
          <v-card-text>
            Als wir versucht haben den Benutzer mit deinen Angaben zu erstellen
            ist folgender Fehler aufgetreten:<br />
            {{ error.reason }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="
                $router.replace({
                  path: `/admin/users`,
                })
              "
              >OK</v-btn
            >
          </v-card-actions>
        </template>
        <template v-if="error.code === 'unknownError'">
          <v-card-title style="word-break: break-word;">
            Unbekannter Fehler beim Erstellen des Benutzers
          </v-card-title>
          <v-card-text>
            Als wir versucht haben den Benutzer mit deinen Angaben zu erstellen
            ist ein unbekannter Fehler aufgetreten<br />
            Fehlercode: {{ error.reason }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="error = { active: false, code: '', reason: null }"
              >OK</v-btn
            >
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { db, auth, func } from '@/firebase';
import AdminHeader from '@/components/AdminHeader';

export default {
  name: 'AdminNewUser',
  components: { AdminHeader },
  data() {
    return {
      campaigns: null,
      userForm: {
        email: '',
        name: '',
        admin: false,
        canDebit: [],
      },
      rules: {
        required: (value) =>
          (!!String(value).trim().length &&
            (typeof value !== 'number' || !isNaN(value))) ||
          'Dies ist ein Pflichtfeld',
      },
      valid: true,
      loading: false,
      error: {
        active: false,
        code: '',
        reason: null,
      },
    };
  },
  firestore() {
    return {
      campaigns: db.doc('aggregation/campaigns'),
    };
  },
  computed: {
    campaignItems() {
      if (
        !this.campaigns ||
        !this.campaigns.campaigns ||
        !Array.isArray(this.campaigns.campaigns)
      )
        return [];
      return this.campaigns.campaigns.map((campaign) => ({
        text: campaign.name,
        value: campaign.id,
      }));
    },
  },
  methods: {
    async addUser() {
      let valid = this.$refs.userForm.validate();
      if (!valid) return;

      this.loading = true;

      let newData = {
        email: this.userForm.email + '@franz-haniel-gymnasium.eu',
        name: this.userForm.name,
        admin: this.userForm.admin,
        canDebit: this.userForm.canDebit,
      };

      try {
        const addUser = func.httpsCallable('addUser');
        let result = await addUser(newData);
        result = result.data;
        if (!result.success) {
          this.error = {
            active: true,
            code: 'registerError',
            reason: result.error.errorInfo.message,
          };
          return;
        }

        await auth.sendPasswordResetEmail(result.user.email, {
          url: window.location.origin + '/d',
        });

        this.$router.push({
          path: `/admin/users?snackbar=created`,
        });
      } catch (err) {
        this.error = { active: true, code: 'unknownError', reason: err };
      } finally {
        this.loading = false;
      }
    },
    changeAdmin(admin) {
      if (!admin) return;
      this.userForm.canDebit = [];
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/views/admin/admin.scss';

.new-user-page {
  .container {
    .input {
      border-radius: 8px;

      &.email {
        margin-top: 32px;
      }
    }

    .subheading {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
    }

    .submitBtn {
      margin: 16px 0 16px 0;
    }
  }
}
</style>
