var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-user-page admin-page"},[_c('div',{staticClass:"container"},[_c('AdminHeader'),_c('div',{staticClass:"heading"},[_c('v-breadcrumbs',{staticClass:"breadcrumbs",attrs:{"divider":"<v-icon>mdi-chevron-right</v-icon>","items":[
          {
            text: 'Benutzer',
            to: '/admin/users',
          },
          {
            text: 'Erstellen',
            to: _vm.$route.path,
          } ]},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true},{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('v-breadcrumbs-item',{class:item.to == _vm.$route.path ? 'active' : '',attrs:{"to":item.to == _vm.$route.path ? '' : item.to,"exact":"","exact-active-class":"active","active-class":""}},[(item.to == _vm.$route.path)?_c('h2',{staticClass:"breadcrumbItem"},[_vm._v(" "+_vm._s(item.text)+" ")]):_c('p',{staticClass:"breadcrumbItem"},[_vm._v(_vm._s(item.text))])])]}}])})],1),_c('v-form',{ref:"userForm",staticClass:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticClass:"email input",attrs:{"outlined":"","label":"E-Mail","placeholder":"max.mustermann","suffix":"@franz…asium.eu","rules":[_vm.rules.required],"tabindex":"1"},model:{value:(_vm.userForm.email),callback:function ($$v) {_vm.$set(_vm.userForm, "email", $$v)},expression:"userForm.email"}}),_c('v-text-field',{staticClass:"name input",attrs:{"outlined":"","label":"Name","placeholder":"Max Mustermann","rules":[_vm.rules.required],"tabindex":"2"},model:{value:(_vm.userForm.name),callback:function ($$v) {_vm.$set(_vm.userForm, "name", $$v)},expression:"userForm.name"}}),_c('h3',{staticClass:"subheading"},[_vm._v("Berechtigungen")]),_c('v-switch',{staticClass:"admin input",attrs:{"label":"Administrator","inset":"","tabindex":"3"},on:{"change":_vm.changeAdmin},model:{value:(_vm.userForm.admin),callback:function ($$v) {_vm.$set(_vm.userForm, "admin", $$v)},expression:"userForm.admin"}}),_c('v-select',{staticClass:"canDebit input",attrs:{"items":_vm.campaignItems,"label":"Abbuchung bei","multiple":"","outlined":"","tabindex":"4","disabled":_vm.userForm.admin,"hint":_vm.userForm.admin
            ? 'Administratoren können bei allen Kampagnen Abbuchungen vornehmen'
            : null,"persistent-hint":_vm.userForm.admin},model:{value:(_vm.userForm.canDebit),callback:function ($$v) {_vm.$set(_vm.userForm, "canDebit", $$v)},expression:"userForm.canDebit"}}),_c('v-btn',{staticClass:"submitBtn",attrs:{"color":"primary","elevation":"0","tabindex":"5","loading":_vm.loading},on:{"click":function($event){return _vm.addUser()}}},[_vm._v("Benutzer hinzufügen")])],1)],1),_c('v-dialog',{attrs:{"elevation":"10","persistent":"","max-width":"500"},model:{value:(_vm.error.active),callback:function ($$v) {_vm.$set(_vm.error, "active", $$v)},expression:"error.active"}},[_c('v-card',[(_vm.error.code === 'registerError')?[_c('v-card-title',{staticStyle:{"word-break":"break-word"}},[_vm._v(" Fehler beim Erstellen des Benutzers ")]),_c('v-card-text',[_vm._v(" Als wir versucht haben den Benutzer mit deinen Angaben zu erstellen ist folgender Fehler aufgetreten:"),_c('br'),_vm._v(" "+_vm._s(_vm.error.reason)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$router.replace({
                path: "/admin/users",
              })}}},[_vm._v("OK")])],1)]:_vm._e(),(_vm.error.code === 'unknownError')?[_c('v-card-title',{staticStyle:{"word-break":"break-word"}},[_vm._v(" Unbekannter Fehler beim Erstellen des Benutzers ")]),_c('v-card-text',[_vm._v(" Als wir versucht haben den Benutzer mit deinen Angaben zu erstellen ist ein unbekannter Fehler aufgetreten"),_c('br'),_vm._v(" Fehlercode: "+_vm._s(_vm.error.reason)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.error = { active: false, code: '', reason: null }}}},[_vm._v("OK")])],1)]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }